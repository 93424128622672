import React, { useState, useEffect } from 'react'
import queryString from 'query-string'
import Page from '../components/Page'
import Container from '../components/Container'
import { observer } from 'mobx-react-lite'
import { useMst } from '../models/Root'
import { MediaElement } from '../components/MediaElement'
import styled from 'styled-components'
import { breakpoints, colors } from '../styles/variables'
import { Input } from '../components/Auth/shared'
// @ts-ignore
import SearchIcon from '../resources/icons/search.svg'
import { navigate } from 'gatsby'
import analytics from '../analytics'
import { logEvent } from 'firebase/analytics'

const SearchResultsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(5, minmax(200px, auto));
  column-gap: 20px;
  justify-content: center;
  align-content: center;
  width: 100%;

  @media (max-width: 1280px) {
    grid-template-columns: repeat(5, minmax(150px, auto));
    column-gap: 2%;
  }

  @media ${`(max-width: ${breakpoints.md}px)`} {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-content: flex-start;
    width: 100%;
    margin-left: 0;
  }
`

const StyledInput = styled(Input)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 60%;
  margin: 0 auto;
  background-color: ${colors.white};
  color: ${colors.black};
  border: 2px solid ${colors.black};
  border-radius: 100px;
  margin-bottom: 20px;
`
const DynamicResult = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  width: 60%;
  margin: 0 auto;
  background-color: ${colors.white};
  color: ${colors.black};
  border: 1px solid ${colors.black};
  margin-bottom: 4px;
  padding: 0 8px;
`

const SearchResultName = styled.span`
  display: inline-block;
  text-align: left;
  flex-grow: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const IsSeries = styled.span`
  display: inline-block;
  text-align: right;
`

const ShowAllButton = styled.button`
  display: block;
  margin: 0 auto;
  margin-top: 20px;
  margin-bottom: 20px;
  background: none;
  border: none;
  cursor: pointer;
  color: ${colors.deiRed};
`
const SortButton = styled.button`
  display: inline-block; // To ensure they are next to each other
  margin-right: 10px; // A bit of spacing between each button
  padding: 6px 12px; // minimal padding
  background: none; // no background
  border: none; // no border
  cursor: pointer;
  color: ${colors.deiRed}; // keeping the color same as ShowAllButton
  transition: opacity 0.3s ease; // subtle transition effect

  &:hover {
    opacity: 0.7; // slight fade on hover for minimal effect
  }

  &:last-child {
    margin-right: 0; // removes the margin from the last button
  }
`
const FilterButton = styled.button`
  display: inline-block;
  margin-right: 10px;
  padding: 6px 12px;
  background: none;
  border: none;
  cursor: pointer;
  color: ${colors.deiRed};
  transition: opacity 0.3s ease;

  &:hover {
    opacity: 0.7;
  }
`

const SearchResultsPage = observer(() => {
  const [searchValue, setSearchValue] = useState('')
  const [shouldDisplaySearch, setShouldDisplaySearch] = useState(false)
  const [skip, setSkip] = useState(0)
  const [take, setTake] = useState(25)
  const [hasMore, setHasMore] = useState(true)
  const [searchTerm, setSearchTerm] = useState('')
  const [page, setPage] = useState(1)
  const [sortField, setSortField] = useState('title')
  const [sortOrder, setSortOrder] = useState('asc')
  const [filterByVideo, setFilterByVideo] = useState(false)

  const {
    search: { searchResults, doSearch }
  } = useMst()

  const toggleSortField = (field: any) => {
    if (sortField === field) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc')
    } else {
      setSortField(field)
      setSortOrder('asc')
    }
  }

  const performSearch = async (searchTerm: any, skip: any, take: any) => {
    await doSearch(searchTerm, skip, take)
    return searchResults.slice(skip, skip + take)
  }

  const loadMoreResults = async () => {
    if (!hasMore) return
    setPage(page + 1)
    const newResults = await performSearch(searchTerm, skip, take * page)
    if (newResults.length < take * page) setHasMore(false)
  }

  useEffect(() => {
    if (analytics) {
      logEvent(analytics, 'screen_visited', {
        screen: 'hakutulokset'
      })
    }
  }, [])

  let { search } = queryString.parse(location.search)

  const navigateToMediaSeries = (item: any) => {
    if (!item.mediaSeriesId) {
      navigate(`/ohjelmasarja/${item.id}`)
    } else if (item.mediaSeriesId && item.xmlId && item.xmlId !== -1) {
      navigate(`/ohjelmasarja/${item.mediaSeriesId}#b${item.xmlId}`)
    } else {
      navigate(`/ohjelmasarja/${item.mediaSeriesId}#b${item.id}`)
    }
  }

  const sortResults = (results: any[]) => {
    const sortedResults = [...results]

    sortedResults.sort((a, b) => {
      let compareA, compareB

      if (sortField === 'title') {
        compareA = a[sortField] || ''
        compareB = b[sortField] || ''
      } else if (sortField === 'premierDate') {
        compareA = a[sortField] ? new Date(a[sortField]).getTime() : Infinity
        compareB = b[sortField] ? new Date(b[sortField]).getTime() : Infinity
      }

      if (sortOrder === 'asc') {
        return compareA > compareB ? 1 : -1
      } else {
        return compareA < compareB ? 1 : -1
      }
    })

    return sortedResults
  }

  const renderDynamicSearchResults = () => {
    const sortedResults = sortResults(searchResults)

    if (sortedResults.length > 0 && searchValue.length > 3) {
      return sortedResults.slice(0, 25).map((item, index) => {
        if (item && item.showName) {
          return (
            <DynamicResult key={index} onClick={() => navigateToMediaSeries(item)}>
              <SearchResultName>{item.title ?? item.showName}</SearchResultName>
              <IsSeries>{item.seriesLink ? 'sarja' : 'ohjelma'}</IsSeries>
            </DynamicResult>
          )
        }
      })
    }
  }

  useEffect(() => {
    const performSearchAndUpdate = async () => {
      if (search) {
        const results = await performSearch(search, 0, take)
        setHasMore(results.length === take)
      }
      if (searchValue.length > 3) {
        setTimeout(async () => {
          const results = await performSearch(searchValue, 0, take)
          setHasMore(results.length === take)
        }, 100)
      }
    }

    performSearchAndUpdate()
  }, [search, searchValue, take])

  const renderSearchResults = () => {
    const resultsToDisplay = filterByVideo ? searchResults.filter((item) => item?.type === 'video') : searchResults

    return (
      <>
        <SearchResultsContainer>
          {sortResults(resultsToDisplay).map((item) => (
            <React.Fragment key={item.id + '-' + item.title + '-' + item.showName}>
              <MediaElement
                id={item.id}
                title={item.title ?? item.showName}
                showName={item.showName ?? 'Untitled'}
                showLikeButton={item.showLikeButton}
                duration={item.duration ? item.duration : undefined}
                pictureSrc={item.pictureSrc ?? ''}
                seriesLink={item.seriesLink}
                mediaSrc={item.mediaSrc || ''}
                mediaSeriesId={item.mediaSeriesId ?? undefined}
                videoSeries={item.videoSeries ?? false}
                mediaType={item.type ?? undefined}
                videoType={item.videoType ?? undefined}
                videoUrl={item.originalUrl ?? ''}
              />
            </React.Fragment>
          ))}
        </SearchResultsContainer>
        {hasMore && <ShowAllButton onClick={() => loadMoreResults()}>Lisää</ShowAllButton>}
      </>
    )
  }

  const handleKeyDown = (event: any) => {
    if (event.key === 'Enter' && searchValue) {
      setSkip(0)
      setHasMore(true)
      setSearchTerm(searchValue)
      doSearch(searchValue, skip, take)
      setShouldDisplaySearch(true)
    }
  }

  return (
    <Page>
      <StyledInput
        value={searchValue}
        onChange={setSearchValue}
        onKeyDown={handleKeyDown}
        label={''}
        placeholder={'Hae hakusanalla...'}
        type={'text'}
      />
      <Container>
        {shouldDisplaySearch && (
          <div style={{ display: 'flex', justifyContent: 'center', marginBottom: 10 }}>
            <SortButton onClick={() => toggleSortField('title')}>
              Nimi {sortField === 'title' ? (sortOrder === 'asc' ? '↓' : '↑') : ''}
            </SortButton>
            <SortButton onClick={() => toggleSortField('premierDate')}>
              Julkaisupäivä {sortField === 'premierDate' ? (sortOrder === 'asc' ? '↓' : '↑') : ''}
            </SortButton>
            <FilterButton onClick={() => setFilterByVideo((prev) => !prev)}>
              {filterByVideo ? 'Näytä kaikki' : 'Näytä vain videot'}
            </FilterButton>
          </div>
        )}

        {searchResults.length > 0 && !shouldDisplaySearch && renderDynamicSearchResults()}
        {searchResults.length > 0 && shouldDisplaySearch && renderSearchResults()}
      </Container>
    </Page>
  )
})

export default SearchResultsPage
